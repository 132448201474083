<template>
  <page-view class="case-more" v-infinite-scroll="loadScroll" :infinite-scroll-disabled="disabled"
    :infinite-scroll-immediate="false" :up-class="'case-more'">
    <div class="case-content">
      <div class="case-item" v-for="item in projectList" :key="item.id">
        <div class="case-item-box">
          <img class="item-img" @click="toDetail(item, projectList)" :src="item.image" alt="" />
          <div class="case-info">
            <div class="item-title" @click="toDetail(item, projectList)">
              {{ item.title }}
            </div>
            <div class="item-describe"><span></span>{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-empty description="暂无数据" style="min-height: 385px" v-if="projectList.length < 1"></el-empty>
    <div class="load-scroll">
      <center v-if="loading">
        <div class="bouncing-bar">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </center>
      <center v-if="noMore && projectList.length > 0">没有更多了</center>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "DesignComAll",
  data() {
    return {
      input: "",
      projectList: [],
      total: 0, //总条数
      current: 0, //当前页,
      loading: false,
      categoryId: 2,
    };
  },
  computed: {
    ...mapState(["caseMore"]),
    noMore() {
      return this.projectList.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  created() {
    document.title = `百川商展`;
    //是公司主页更多
    if (this.caseMore.syscompanyid != null) {
      this.getComListId();
    } else {
      //是类型公司更多
      this.current = 1//从第二页开始，客户说上一个页面看过的三张就不要显示在中国列表了
      this.getListChoose();
    }
  },
  methods: {
    ...mapActions(["setTheArticle", "setTheArticleList"]),
    //获取更多公司主页文章
    async getComListId() {
      //获取文章类别编号
      let result = await this.$api.getComListId({
        conpanyid: this.caseMore.syscompanyid,
      });
      this.categoryId = result.data.find((item) => item.bcce === this.caseMore.bcce)?.id;
      //获取项目案例
      let resultProject = await this.$api.getComList({
        categoryId: this.categoryId,
        index: this.current,
        size: 4,
        type: 16,
      });
      this.projectList = resultProject.data?.list;
      this.total = resultProject.data?.size;
    },
    //获取类型公司文章
    async getListChoose() {
      let result = await this.$api.getListChoose({
        bcCompanyType: this.caseMore.bcCompanyType,
        bcce: this.caseMore.bcce,
        type: 16,
        index: this.current,
        size: 3,
      });
      this.projectList = result.data;
      this.total = result.size;
    },
    
    async loadScroll() {
      if (this.disabled) {
        return false; //没有更多了
      }
      this.loading = true;
      this.current++
      if (this.caseMore.syscompanyid != null) {
        let resultProject = await this.$api.getComList({
          categoryId: this.categoryId,
          index: this.current,
          size: 4,
          type: 16,
        });
        this.projectList.push(...resultProject.data?.list);
      } else {
        let result = await this.$api.getListChoose({
          bcCompanyType: this.caseMore.bcCompanyType,
          bcce: this.caseMore.bcce,
          type: 16,
          index: this.current,
          size: 4,
        });
        this.projectList.push(...result.data);
      }
      this.loading = false;
    },




    toDetail(item, _arr) {
      this.setTheArticle(item);
      // 修改文章列表
      this.setTheArticleList(_arr);
      const openRoute = this.$router.resolve({
        name: "ExcellentDesign",
      });
      window.open(openRoute.href, "_blank");
    },
    toComIndex(syscompanyid) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.case-more {
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;

  .case-content {
    width: $containerWidth;
    margin: 0px auto;
    margin-bottom: 60px;
    padding: 50px 0;
    margin-top: 15px;

    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 50px;
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 0px;
      padding: 10px 0;
    }

    .case-item {
      background-color: #fff;
      padding: 0 20px;
      box-sizing: border-box;

      .case-item-box {
        @include flexbox(row);
        padding: 20px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f0f0f0;
      }

      &:last-child {
        border: none;
      }

      .item-img {
        cursor: pointer;
        width: 200px;
        height: 138px;

        @media screen and (max-width: 1200px) {
          width: 2rem;
          height: 1.38rem;
        }
      }

      .case-info {
        flex: 1;
        overflow: auto;
        margin-left: 20px;

        .item-title {
          cursor: pointer;
          font-size: 21px;
          display: -webkit-box;
          overflow: hidden;
          /*超出隐藏*/
          text-overflow: ellipsis;
          /*隐藏后添加省略号*/
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //想显示多少行

          @media screen and (max-width: 1200px) {
            font-size: 18px;
          }
        }

        .item-describe {
          color: #606266;
          font-size: 14px;
          margin-bottom: 12px;
          margin-top: 10px;
          display: -webkit-box;
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //隐藏后添加省略号
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; //想显示多少行

          @media screen and (max-width: 1200px) {
            -webkit-line-clamp: 2; //想显示多少行
          }
        }

        .item-describe1 {
          width: 70%;
          display: -webkit-box;
          overflow: hidden;
          /*超出隐藏*/
          text-overflow: ellipsis;
          /*隐藏后添加省略号*/
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //想显示多少行

          :deep(img) {
            display: none;
          }
        }
      }
    }
  }

  .load-scroll {
    padding: 30px 0;
    margin-bottom: 50px;

    .bouncing-bar {
      display: flex;
      justify-content: center;
    }

    .line {
      height: 20px;
      width: 4px;
      background: #439c4c;
      margin-right: 4px;
      border-radius: 6px;
      animation: line-bounce 1s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.3s;
      }

      &:nth-child(4) {
        animation-delay: 0.4s;
      }
    }

    @keyframes line-bounce {
      0% {
        transform: scale(1);
      }

      20% {
        transform: scale(1, 2);
      }

      40% {
        transform: scale(1);
      }
    }
  }
}</style>
